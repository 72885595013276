import { useState, useEffect } from 'react';
import { getRandomInt } from 'utils';

const images: string[] = [
  '42636-loading-52-mario.json',
  '62761-walking-pothos.json',
  '63272-walking-avocado.json',
  '37725-loading-50-among-us.json',
  '62338-coffee-time.json',
  '62450-french-fries.json',
];

export const useRandomLoadingAnimation = () => {
  const [animationData, setImg] = useState<null | Object>();

  useEffect(() => {
    const number = getRandomInt(0, images.length);
    import(`../assets/${images[number]}`)
      .then(data => setImg(data.default))
      .catch(e => setImg(null));
  }, []);

  return animationData;
}