import React from 'react';
import { Nav } from 'rsuite';
import { Link } from 'react-router-dom';

export const NavLink: React.FC<any> = (props) => {
  return (
    <Nav.Item
      className="navbar-link"
      style={{ margin: 5 }}
      as={Link}
      {...props}
    />
  );
};
