import React from 'react';
import { Panel } from 'rsuite';
import { useTitle } from 'hooks/useTitle';

interface NotFoundProps {

}

export const NotFound: React.FC<NotFoundProps> = () => {
  useTitle('Not Found');

  return (
    <Panel>
      Не найдено
    </Panel>
  )
}