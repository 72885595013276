import React, { useState } from 'react';
import { Button, ButtonToolbar, Col, FlexboxGrid, Form, Panel, Schema } from 'rsuite';
import { useHistory } from 'react-router-dom';
import { TextField } from 'components';
import { useTitle } from 'hooks/useTitle';
import { ROUTES } from 'configs/routesConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { firebaseAuth } from 'configs/firebaseConfig';

const { StringType } = Schema.Types;
const model = Schema.Model({
  password: StringType().isRequired('This field is required.'),
  email: StringType()
    .isEmail('Please enter a valid email address.')
    .isRequired('This field is required.')
});

export const Login: React.FC = () => {
  useTitle('Login');

  const [formValues, setValues] = useState<Record<string, any>>();
  const history = useHistory();

  const handleLogin = ({ email, password }: Record<string, any>) => {
    signInWithEmailAndPassword(firebaseAuth, email, password)
      .then(() => {
        setTimeout(() => history.push(ROUTES.INTERVIEWS), 0);
      })
      .catch((error: any) => {
        history.push(ROUTES.ERROR);
      });
  }

  return (
    <FlexboxGrid justify="center" align="middle" style={{ height: 'calc(100vh - 80px)' }}>
      <FlexboxGrid.Item colspan={12} as={Col}  xs={22} sm={16} md={8}>
        <Panel bordered shaded>
          <Form
            fluid
            onChange={setValues}
            model={model}
            onSubmit={() => formValues && handleLogin(formValues)}
          >
            <TextField name="email" label="Email" />
            <TextField name="password" label="Password" type="password"  />
            <ButtonToolbar>
              <Button appearance="primary" type="submit">
                Войти
              </Button>
            </ButtonToolbar>
          </Form>
        </Panel>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}