import { useCallback, useState } from 'react';

export const useSidebar = (width: number, initialState = false) => {
  const [show, setShow] = useState<boolean>(initialState);

  const close = useCallback(() => setShow(false), []);
  const open = useCallback(() => setShow(true), []);
  const toggle = useCallback(() => setShow(prev => !prev), []);

  return {
    show,
    close,
    open,
    toggle,
    width
  };
};