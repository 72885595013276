import React, { Suspense } from 'react';
import { useAuth } from 'contexts/authContext';
import { useRedirect } from 'hooks/useRedirect';
import { navigation, ROUTES } from 'configs/routesConfig';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useSidebar } from 'hooks/useSidebar';
import { SideBar } from './SideBar';
import { Affix, Avatar, Button, Container, Dropdown, IconButton, Loader, Nav, Navbar, Stack } from 'rsuite';
import LegacyUserIcon from "@rsuite/icons/legacy/User";
import LegacyExitIcon from "@rsuite/icons/legacy/Exit";
import LegacyBarsIcon from "@rsuite/icons/legacy/Bars";
import { NavLink } from './NavLink';
import { AdminGuard, BrandText, UsersStack } from './index';
const SIDEBAR_WIDTH = 125;

const navBarStyles = {
  paddingLeft: 8,
  height: 60,
  display: 'flex',
  alignItems: 'center'
}

export const AdminLayout: React.FC = ({ children }) => {
  const {user, signOut} = useAuth();
  const redirect = useRedirect();
  const handleExit = () => {
    signOut();
    redirect(ROUTES.LOGIN)();
  };

  const isMobile = useMediaQuery('(max-width: 650px)');
  const sidebar = useSidebar(SIDEBAR_WIDTH, !isMobile);

  return (
    <SideBar width={sidebar.width} show={sidebar.show}>
      <SideBar.Header>
        <Nav vertical>
          {Object.entries(navigation).map(([route, [label, icon]],) => (
            <NavLink key={route} to={route}>
              <Stack
                spacing={6}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <span>{icon}</span>
                <span>{label}</span>
              </Stack>
            </NavLink>
          ))}
          <NavLink to={ROUTES.HOME}>
            <Stack
              spacing={6}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <span>🏠</span>
              <span>Home</span>
            </Stack>
          </NavLink>
        </Nav>
      </SideBar.Header>

      <SideBar.Content>
        <Affix>
          <Navbar style={navBarStyles}>
            <BrandText value="interview"/>
            <Navbar.Body>
              <AdminGuard>
                <Nav pullRight>
                  <UsersStack/>
                  <Dropdown
                    user={user}
                    className="user-dropdown"
                    placement="bottomEnd"
                    renderToggle={(props: any, ref:React.Ref<any>) => {
                      return  (
                        <Button {...props} ref={ref} style={{ borderRadius: 0 }}>
                          <Stack spacing={12}>
                            <Avatar circle src={props.user.photoURL} imgProps={{ style: { objectFit: 'cover' } }}/>
                            <span>
                              {props.user.displayName}
                            </span>
                          </Stack>
                        </Button>
                      )
                    }}
                  >
                    <Dropdown.Item onClick={redirect(ROUTES.PROFILE)} icon={<LegacyUserIcon />}>
                      Профиль
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleExit} icon={<LegacyExitIcon />}>
                      Выход
                    </Dropdown.Item>
                  </Dropdown>
                </Nav>
              </AdminGuard>
            </Navbar.Body>
          </Navbar>
        </Affix>

        <Suspense fallback={<Loader center speed="fast" content="Подключаюсь к спутникам"/>}>
          <Container style={{padding: 10}}>
            {children}
            <AdminGuard>
              <IconButton
                className="nav-btn"
                circle
                size="lg"
                icon={<LegacyBarsIcon />}
                onClick={sidebar.toggle}
              />
            </AdminGuard>
          </Container>
        </Suspense>
      </SideBar.Content>
    </SideBar>
  );
};

export const withAdminLayout = (Component: any) => (props: Record<string, any>) => {
  return (
    <AdminLayout>
      <Component {...props}/>
    </AdminLayout>
  )
}
