import React, { useMemo } from 'react';
import { Nav, Panel } from 'rsuite';
import { navigation } from 'configs/routesConfig';
import { Link } from 'react-router-dom';
import { useInterviews } from 'hooks/useInterviews';
import {
  max,
  min,
  format,
  isAfter,
  isBefore,
  eachWeekOfInterval,
  lastDayOfWeek
} from 'date-fns';
import { ru } from 'date-fns/locale';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

interface HomeProps {
}

export const Home: React.FC<HomeProps> = () => {
  const interviews = useInterviews();

  const data = useMemo<any[]>(() => {

    const dates = interviews.list.map(interview => interview.createdAt).map(d => new Date(d));
    if (dates.length) {
      const weeks = eachWeekOfInterval({
        start: min(dates),
        end: max(dates)
      });

      const labels = weeks.map(week => {
        const options = {locale: ru};
        const pattern = 'dd/MM';
        return `${format(week, pattern, options)} - ${format(lastDayOfWeek(week), pattern, options)}`;
      });

      const list = interviews.list.map(i => ({
        ...i,
        createdAt: new Date(i.createdAt),
      }));

      const filterByDate = (week: any) => ({createdAt}: any) => {
        return isAfter(createdAt, week) && isBefore(createdAt, lastDayOfWeek(week))
      };

      const getLength = (list: any[]) => list.length;
      const pass = weeks
        .map(month => list
          .filter(filterByDate(month))
          .filter((interview => {
            const {startTime} = interview;
            return startTime;
          }))
        )
        .map(getLength);

      const planed = weeks
        .map(month => list
          .filter(filterByDate(month))
          .filter((interview => {
            const {startTime} = interview;
            return !startTime;
          }))
        )
        .map(getLength);

      return labels.map((label, index) => {
        return {
          name: label,
          pass: pass[index],
          planned: planed[index],
        }
      })
    }

    return [];

  }, [interviews.list]);

  return (
    <React.Fragment>
      <div style={{ width: '100%', height: 300 }}>
        <ResponsiveContainer>
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area type="monotone" name="Прошли тест" dataKey="pass" stackId="1" stroke="#8884d8" fill="#8884d8" />
            <Area type="monotone" name="Запланированы" dataKey="planned" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <Panel bordered>
        <Nav vertical>
          {Object.entries(navigation).map(([route, [label, icon]],) => (
            <Nav.Item as={Link} key={route} to={route} icon={<span>{icon} </span>}>{label}</Nav.Item>
          ))}
        </Nav>
      </Panel>
    </React.Fragment>
  );
};