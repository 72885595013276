import { useAuth } from 'contexts/authContext';
import { Redirect, Route } from 'react-router-dom';
import React, { useMemo } from 'react';
import { Loader } from 'rsuite';
import Lottie from 'react-lottie';
import { useRandomLoadingAnimation } from 'hooks/useRandomLoadingAnimation';
import { AdminLayout } from './AdminLayout';

export const PrivateRoute = (props: any) => {
  const auth = useAuth();

  if (auth.loading) {
    return <PageLoader/>
  }

  if (!auth.user || !auth.isAdmin) {
    return <Redirect to='/login'/>;
  }

  return (
    <AdminLayout>
      <Route {...props} />
    </AdminLayout>
  )
};


const PageLoader = () => {
  const animationData = useRandomLoadingAnimation();

  const config = useMemo(() => {
    return {
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
  }, [animationData]);

  return (
    <div className="private-router-loader">
      <Loader
        backdrop
        content={(
          <Lottie
            options={config}
            height={200}
            width={200}
            isStopped={false}
            isPaused={false}
          />
        )}
      />
    </div>
  )
}