import {bitlyConfig} from 'configs/bitlyConfig'
import React from 'react';
import { Interview } from 'hooks/useInterviews';
import { isAfter } from 'date-fns';

// https://ourcodeworld.com/articles/read/97/how-to-convert-url-websites-email-from-a-string-to-html-a-tags-with-javascript
export const linkify = (inputText: string): string =>  {
  var replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  // eslint-disable-next-line
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  // eslint-disable-next-line
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

  //Change email addresses to mailto:: links.
  // eslint-disable-next-line
  replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText;
}

export const getInterviewerDescription = (interview: any) => {
  return  `\n${interview.name} ${interview.lastName}
      \n${interview.templateName}
      \n${interview.description ? interview.description : '-' }
      `
}

export const getFullName = (interview: any): string => {
  return `${interview.name} ${interview.lastName}`;
}

export function scrollTop() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

export function scrollBottom() {
  document.body.scrollTop = document.body.scrollHeight; // For Safari
  document.documentElement.scrollTop = document.body.scrollHeight; // For Chrome, Firefox, IE and Opera
}

export const createInviteLink = (email: string, password: string ) => {
  return `${window.location.origin}/invite/${btoa(JSON.stringify([email, password]))}`
}

export const shortenLink = async(link: string):Promise<string> => {
    return fetch(bitlyConfig.URL, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${bitlyConfig.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ long_url: link, "domain": "bit.ly" })
    }).then(response => response.json())
    .then(data => data.link)
    .catch(e => console.error(e))
}

export function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
}

export const findByType = (children: any, component: React.FC<any>) => {
  const result: any[] = [];
  /* This is the array of result since Article can have multiple times the same sub-component */
  const type = [component.displayName] || [component.name];
  /* We can store the actual name of the component through the displayName or name property of our sub-component */
  React.Children.forEach(children, (child) => {
    const childType =
      child && child.type && (child.type.displayName || child.type.name);
    if (type.includes(childType)) {
      result.push(child);
    }
  });
  /* Then we go through each React children, if one of matches the name of the sub-component we’re looking for we put it in the result array */
  return result[0];
};

const PROD_HOST = 'maybeworks.dev';


export const isProduction = () => window.location.origin.includes(PROD_HOST);

export const IS_PROD = isProduction();

export const isInterviewExpired = (interview: Interview) => {
  if (interview.isExpired !== undefined) {
    return interview.isExpired
  }

  if (interview.expired !== undefined) {
    return isAfter(new Date(), interview.expired)
  }

  return false

}