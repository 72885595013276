import React from 'react';
import { useAuth } from 'contexts/authContext';

const AdminGuard: React.FC<any> = ({ children }) => {
  const auth = useAuth();

  if (auth.user && auth.isAdmin) {
    return typeof children === 'function' ? children(auth) : children
  }

  return null;
};

export default AdminGuard;