import { initializeApp, getApp} from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getRemoteConfig, getString } from "firebase/remote-config";

interface Config {
  apiKey: string,
  authDomain: string,
  projectId: string,
  storageBucket: string,
  messagingSenderId: string,
  appId: string,
  measurementId: string,
  databaseURL: string
};

export const firebaseConfig: Config = {
  apiKey: "AIzaSyDKmAM1lRJ43SD3v9ToE1lQo3ap8k24FQA",
  authDomain: "mw-interview.firebaseapp.com",
  projectId: "mw-interview",
  storageBucket: "mw-interview.appspot.com",
  messagingSenderId: "1089880739215",
  appId: "1:1089880739215:web:dd5a0605b54c8158e3cffa",
  measurementId: "G-0D004EHGXB",
  databaseURL: 'https://mw-interview-default-rtdb.europe-west1.firebasedatabase.app'
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(firebaseApp);

export const remoteConfig = getRemoteConfig(firebaseApp);

remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

remoteConfig.defaultConfig = {
  "prod_domain": "maybeworks.dev",
  'dev_hr_channel':''
};

const prodDomain = getString(remoteConfig, 'prod_domain');

export const registerApp = initializeApp(firebaseConfig, 'Secondary');

export const IS_PROD = window.location.origin.includes(prodDomain);

if (!IS_PROD) {
  const db = getDatabase();
  const storage = getStorage();
  const firestore = getFirestore();

  const functions = getFunctions(getApp());

  connectAuthEmulator(firebaseAuth, "http://localhost:9099");
  connectAuthEmulator(getAuth(registerApp), "http://localhost:9099");
  connectDatabaseEmulator(db, "localhost", 9000);
  connectStorageEmulator(storage, "localhost", 9199);
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);

}

