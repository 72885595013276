import { AppVersion, Logo } from 'components';
import React, { useMemo } from 'react';
import { findByType } from 'utils';
import styled from 'styled-components';

interface SideBarProps {
  show: boolean;
  width: number;
}

const Content: React.FC<any> = (props) => props.children;
Content.displayName = 'Content';

const Header: React.FC<any> = (props) => props.children;
Header.displayName = 'Header';

interface SidebarSubComponents {
  Content: typeof Content;
  Header: typeof Header
}

export const SideBar: React.FC<SideBarProps> & SidebarSubComponents = ({show, width, children}) => {
  const xPosition = useMemo(() => show ? 0 : -width, [show, width]);
  const content = useMemo(() => findByType(children, Content), [children]);
  const header = useMemo(() => findByType(children, Header), [children]);

  return (
    <React.Fragment>
      <SidebarWrapper
        style={{
          transform: `translateX(${xPosition}px)`,
          width
        }}
      >
        <LogoContainer>
          <Logo/>
        </LogoContainer>
        <div className="content">{header?.props?.children}</div>
        <AppVersion/>
      </SidebarWrapper>
      <Main style={{marginLeft: show ? width : 0}}>
        {content?.props?.children}
      </Main>
    </React.Fragment>
  );
};


SideBar.Content = Content;
SideBar.Header = Header;

const Main = styled.div`
  transition: margin-left .4s ease-in-out;
`;

const SidebarWrapper = styled.div`
  background-color: #1a1d24;
  padding-top: 15px;
  position: fixed;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  transition: all .4s ease-in-out;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;
