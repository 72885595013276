import { IconButton, Table } from 'rsuite';
import LegacyTrashIcon from "@rsuite/icons/legacy/Trash";
import React from 'react';

// TODO: rename it to delete actions
export const ActionCell = ({rowData, dataKey, onRemove, ...props}: any) => {
  const handleAction = () => {
    // alert(`id:${rowData[dataKey]}`);
    // eslint-disable-next-line no-restricted-globals
      onRemove(rowData.id);
  };

  return (
    <Table.Cell {...props} className="link-group" style={{padding: '5px 10px'}}>
      <div onClick={e => e.stopPropagation()}>
        <IconButton
          appearance="subtle"
          onClick={handleAction}
          icon={<LegacyTrashIcon />}
        />
      </div>
    </Table.Cell>
  );
};