import React, { Suspense } from 'react';
import './index.less';
import { Container, Loader } from 'rsuite';
import { PrivateRoute } from 'components';
import { Switch } from 'react-router-dom';
import { ErrorAnimation } from 'components/ErrorAnimation';

import {
  Home,
  Interview,
  InterviewManagement,
  InterviewRevision,
  InterviewSummary,
  Invite,
  Login,
  Profile,
  QuestionsList,
  TagsList,
  TemplatesDetails,
  TemplatesList,
} from 'pages';
import { ROUTES } from 'configs/routesConfig';
import { PublicRoute } from './components/PublicRoute';

const App = () => {
  return (
    <Suspense fallback={<Loader center speed="fast" content="Подключаюсь к спутникам"/>}>
      <Container>
        <Switch>
          <PrivateRoute path={ROUTES.QUESTIONS} component={QuestionsList}/>
          <PrivateRoute path={`${ROUTES.TEMPLATES}/:id`} component={TemplatesDetails}/>
          <PrivateRoute path={ROUTES.TEMPLATES} component={TemplatesList}/>
          <PrivateRoute path={`${ROUTES.INTERVIEWS}/:id/summary`} component={InterviewSummary}/>
          <PrivateRoute path={`${ROUTES.INTERVIEWS}/:id/revision`} component={InterviewRevision}/>
          <PrivateRoute exact path={ROUTES.INTERVIEWS} component={InterviewManagement}/>
          <PrivateRoute exact path={ROUTES.TAGS} component={TagsList}/>
          <PrivateRoute path={ROUTES.PROFILE} component={Profile}/>
          <PublicRoute exact path={`${ROUTES.INTERVIEWS}/:id`} component={Interview}/>
          <PublicRoute path={ROUTES.LOGIN} component={Login}/>
          <PublicRoute path={ROUTES.ERROR} component={ErrorAnimation}/>
          <PublicRoute path={`${ROUTES.INVITE}/:payload`} component={Invite}/>
          <PrivateRoute path="/" component={Home}/>
        </Switch>
      </Container>
    </Suspense>
  );
};

export default App;
