import { useCallback, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import firebase from 'firebase/compat/app';
import { getDatabase, query, ref, set, get, remove as removeFromDb, DataSnapshot, onValue, orderByChild } from "firebase/database";

const database = getDatabase();

export interface RowEntity {
  id: string;
  //  date in ISO format
  createdAt: string;
}

export interface useTableInstance<T, U = Record<string, any>> {
  create: (payload: U, id?: string) => Promise<string | undefined>;
  update: (id: string, payload: U, renew?: boolean) => Promise<any>;
  list: T[];
  remove: (id: string) => Promise<any>
  getById: (id: string) => Promise<T | undefined>
  selected: T | undefined
  loading: boolean;
}

export const useTable = <T = any>(tableName: string): useTableInstance<T> => {
  const [list, setList] = useState<T[]>([]);
  const [selected, setSelected] = useState<T>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!firebase) {
      console.error('Firebase must be present');
    }
  }, []);

  const create = useCallback(async (payload: Record<string, any>, id = nanoid(8)): Promise<string | undefined> => {
    try {
      console.info(`Create entity with id - ${id}`);

      await set(ref(database,`${tableName}/${id}`), {
        ...payload,
        id,
        createdAt: new Date().toISOString(),
      });

      return id;
    } catch (e) {
      console.error('Create error - ' + e);
    }
  }, [tableName]);

  const getById = useCallback(async (id: string) => {
    try {
      setLoading(true);

      const response = await get(ref(database,`${tableName}/${id}`))
      const payload = response.val();
      setSelected(payload);
      return payload;
    } catch (e) {
      console.error(`Fail to get getById ${tableName}/${id}`);
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [tableName]);

  const update = useCallback(async (id: string, payload: any, renew = true) => {

    const path = `${tableName}/${id}`;
    await set(ref(database, path), payload)
    if (renew) {
      const response = await get(ref(database, path))
      return response.val()
    }

  }, [tableName]);

  const remove = useCallback((id: string): Promise<void> => {
    return removeFromDb(ref(database,`${tableName}/${id}` ))
  }, [tableName]);

  useEffect(() => {
    const tableRef = ref(database,tableName);

    const handler = (snapshots: DataSnapshot): void => {
      const list: any = [];
      let index = 1;
      snapshots.forEach((snapshot: DataSnapshot) => {
        const dt = snapshot.val();
        dt.index = index++;
        list.push(dt);
      });

      setList(list);
    };

    const unsubscribe = onValue(query(tableRef, orderByChild('createdAt')), handler)

    return () => unsubscribe();

  }, [tableName]);

  return {
    create,
    update,
    list,
    remove,
    getById,
    selected,
    loading,
  };
};