import React, { useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { OnlineUser } from './OnlineUser';
import { usePageTracker } from 'hooks/usePageTracker';
import { useAuth } from 'contexts/authContext';

interface UsersStackProps {

}

const UsersStack: React.FC<UsersStackProps> = () => {
  const users = usePageTracker();
  const auth = useAuth();

  const guests = useMemo(() => users.filter(({ uid }) => uid !== auth.user?.uid), [users, auth.user]);
  return (
    <span style={{ display: 'inline-block' }}>
      <div className="users-stack">
        <AnimatePresence>
          {guests.map((user: any, index: number) => (
            <motion.div
              key={user.uid}
              style={{ position: 'absolute' }}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1, x: -25 * (users.length - index), transition: {
                  delay: index * 0.3,
                },
              }}
              exit={{ opacity: 0, x: 20 }}
            >
              <OnlineUser data={user}/>
            </motion.div>
          ))}
        </AnimatePresence>
    </div>
  </span>
  );
};

export default UsersStack;