import { useAuth } from 'contexts/authContext';
import { Route } from 'react-router-dom';
import React, { useMemo } from 'react';
import { Loader } from 'rsuite';
import Lottie from 'react-lottie';
import { useRandomLoadingAnimation } from 'hooks/useRandomLoadingAnimation';
import { PublicLayout } from './PublicLayout';

export const PublicRoute = (props: any) => {
  const auth = useAuth();
  const animationData = useRandomLoadingAnimation();

  const config = useMemo(() => {
    return {
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
  }, [animationData]);

  if (auth.loading) {
    return (
      <div className="private-router-loader">
        <Loader
          backdrop
          content={(
            <Lottie
              options={config}
              height={200}
              width={200}
              isStopped={false}
              isPaused={false}
            />
          )}
        />
      </div>
    );
  }

  return (
    <PublicLayout>
      <Route {...props} />
    </PublicLayout>
  )
};
