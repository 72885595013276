import { Navbar } from 'rsuite';
import { BrandText, Logo } from './index';
import React from 'react';
import styled from 'styled-components';

const LogoContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 15px;
`;

export const PublicLayout: React.FC = ({ children }) => {
  return (
    <React.Fragment>
      <Navbar style={{ marginBottom: 10}}>
        <Navbar.Body >
          <LogoContainer>
            <Logo/>
            <BrandText value="interview"/>
          </LogoContainer>
        </Navbar.Body>
      </Navbar>
      { children }
    </React.Fragment>
  )
}