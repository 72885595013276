import React from 'react';
import { Avatar, Tooltip, Whisper } from 'rsuite';

interface OnlineUserProps {
  data: {
    uid: string;
    displayName: string;
    photoURL: string
  },
}

export const OnlineUser: React.FC<OnlineUserProps> = React.memo(({data}) => {

  return (
    <Whisper key={data.uid} placement="auto" trigger="hover" speaker={<Tooltip>
      {data.displayName}
    </Tooltip>}>
      <Avatar
        circle
        src={data.photoURL}
      />
    </Whisper>
  );
});