import React from 'react';

interface BrandTextProps {
  value: string
}

const BrandText: React.FC<BrandTextProps> = ({ value = ''}) => {
  return (
    <span className="logo-text">
       maybe.<span className="brand">{value}</span>
    </span>
  );
};

export default BrandText;