import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTable } from './useTable';
import { useAuth } from 'contexts/authContext';
import { getDatabase, ref, set, onValue } from 'firebase/database';

const tableName = 'tracker';

const database = getDatabase();

const getCurrentPath = (): string => window.location.href
  .replaceAll('/', '_')
  .replaceAll('.', '_')
  .replaceAll('#', '_')

export const usePageTracker = () => {
  const tracker = useTable(tableName);
  const auth = useAuth();
  const [path, setPath] = useState<string>();
  const [list, setList] = useState([]);
  const prevLocation = useRef('');
  const history = useHistory();

  const create = useCallback(async (id: string, payload: any) => {
    try {
      await set(ref(database,`${tableName}/${id}`), payload)
      return id;
    } catch (e) {
      console.error('Create error - ' + e);
    }
  }, []);

  useEffect(() => {
    const handler = () => {
      if (path) {
        tracker.remove(path)
      }
    }
    window.addEventListener('beforeunload', handler);

    return () => window.removeEventListener('beforeunload', handler)
  }, [path, tracker]);

  useEffect(() => {
    if (path) {

      const handler = (snapshot: any) => {
        const list: any = [];
        snapshot.forEach((childSnapshot: any) => {
          const dt = childSnapshot.val();
          list.push(dt);
        });
        setList(list);
      };

      const subs = onValue(ref(database,`${tableName}/${path}`), handler)

      return () => subs();
    }

  }, [path, setList])

  useEffect(() => {
    if (!prevLocation.current && auth.user) {
      const path = `${getCurrentPath()}/${auth.user.uid}`;

      prevLocation.current = path;
      // TODO: create a single separate function
      const { photoURL, uid, displayName  } = auth.user;
      create(path, {
        online: true,
        displayName,
        photoURL,
        uid,
        joined: new Date().toISOString(),
      });

      setPath(getCurrentPath());

    }
  }, [auth.user, create]);

  useEffect(() => {
    if (auth.user) {
      history.listen(async () => {
        if (prevLocation.current === getCurrentPath()) {
          return ;
        }
        try {
          if (prevLocation.current) {
            await tracker.remove(prevLocation.current);
          }
          const path = `${getCurrentPath()}/${auth.user.uid}`;

          setPath(getCurrentPath());

          prevLocation.current = path;
          await create(path, {
            online: true,
            displayName: auth.user.displayName,
            photoURL: auth.user.photoURL,
            uid: auth.user.uid,
            joined: new Date().toISOString(),
          });

        } catch (e) {
          console.error(e);
        }

      });

    }
  }, [auth.user, create, history, tracker.remove]);

  return list;
};


