import React, { useContext, useEffect, useState } from 'react';
import { toaster, Message, Loader } from 'rsuite';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from 'contexts/authContext';
import { useInterviews } from 'hooks/useInterviews';
import { ROUTES } from 'configs/routesConfig';
import {  firebaseAuth } from 'configs/firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';

export const Invite: React.FC = () => {
  const {payload} = useParams<{ payload: string }>();
  const auth = useContext(AuthContext);
  const interviews = useInterviews();
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      try {
        await firebaseAuth.signOut().catch();
        const [email, password] = JSON.parse(window.atob(payload));
        await signInWithEmailAndPassword(firebaseAuth, email, password);
        setLoading(false);

      } catch (e) {
        console.error('Payload parse error');
        history.push(ROUTES.ERROR);
      }

    };
    init();
  }, [history, payload]);

  useEffect(() => {
    if (auth.user?.uid && !loading) {
      interviews.findByUserId(auth.user.uid).then(interview => {
        if (interview) {
          history.push(`${ROUTES.INTERVIEWS}/${interview.id}`);
        } else {
          toaster.push(<Message type="error" showIcon closable>Smth went wrong :(</Message>);
          history.push(ROUTES.ERROR);
        }
      });

    }
  }, [interviews, auth.user, loading, history]);

  return (
    <div>
      <Loader content="The Force will be with you. Always" vertical center/>
    </div>
  );
};