
export enum ROUTES {
  QUESTIONS = '/questions',
  TEMPLATES = '/templates',
  INTERVIEWS = '/interviews',
  TAGS = '/tags',
  LOGIN = '/login',
  INVITE = '/invite',
  PROFILE = '/profile',
  ERROR = '/error',
  HOME = '/home',
}

export const navigation: Record<string, [string, string]>= {
  [ROUTES.QUESTIONS]: ['Вопросы', '❔'],
  [ROUTES.TEMPLATES]: ['Шаблоны', '📕'],
  [ROUTES.INTERVIEWS]: ['Интервью', '🐈'],
  [ROUTES.TAGS]: ['Теги', '🏷️'],
};