import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { differenceInSeconds } from 'date-fns';

interface CountdownProps {
  isComplete: boolean;
  onComplete: () => void;
  startTime: number;
  endTime: number;
}

// basic reference
// https://codesandbox.io/s/silly-night-d3s70?fontsize=14&hidenavigation=1&theme=dark&file=/src/index.js
const minuteSeconds = 60;
const hourSeconds = minuteSeconds * 60;

export const Countdown: React.FC<CountdownProps> = ({ isComplete, onComplete, startTime, endTime }) => {

  const getTimeMinutes = (time: any) => {
    return ((time % hourSeconds) / minuteSeconds) | 0;
  };

  const colors: any = [['#FF640C', 0.33], ['#F7B801', 0.33], ['#A30000']];
  return (
    <CountdownCircleTimer
      isPlaying={!isComplete}
      size={50}
      colors={colors}
      duration={differenceInSeconds(endTime, startTime)}
      strokeWidth={5}
      initialRemainingTime={differenceInSeconds(endTime, new Date())}
      onComplete={onComplete}
    >
      {({ elapsedTime }: { elapsedTime: number }) => {
        return (
          <div className="time-wrapper">
            <div className="time">{getTimeMinutes(hourSeconds - elapsedTime)}m</div>
          </div>
        )
      }
      }
    </CountdownCircleTimer>
  );
};


