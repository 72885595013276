import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialOceanic } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

const renderers = {
    code: ({language, value = ''}: any) => {
      return <SyntaxHighlighter showLineNumbers style={materialOceanic} language={language} children={value}/>;
    }
  }
;

export const Markdown: React.FC<{ data: string }> = ({data}) => {
  return <ReactMarkdown renderers={renderers} plugins={[gfm]} children={data}/>;
};

export const markdownSample = `A paragraph with *emphasis* and **strong importance**.

> A block quote with ~strikethrough~ and a URL: https://reactjs.org.

## How about some code?

\`\`\`js
var React = require('react');
var Markdown = require('react-markdown');

React.render(
  <Markdown source="# Your markdown here" />,
  document.getElementById('content')
);
\`\`\`

`;