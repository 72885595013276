import { useCallback, useMemo } from 'react';
import { RowEntity, useTable, useTableInstance } from './useTable';
import { Question } from './useQuestions';
import { getDatabase, ref, query, get } from 'firebase/database';

export interface Interview extends RowEntity {
  index: number;
  name: string;
  lastName: string;
  email: string;
  templateName: string;
  feedback: string;
  questions: Question[];
  reviewed: boolean;
  completed?: boolean;
  startTime?: number;
  endTime?: number
  expired?: number;
  isExpired?: boolean
  duration?: number
  persia?: string
  description?: string
  form?: string
  error?: boolean
  rating?: number
  password?: string;
  inviteLink?: string;
  reviewer?: {
    email: string,
    displayName: string,
    uid: string,
    iconURL: string;
  }
}

export interface useInterviewsState extends useTableInstance<Interview, any>{
  findByUserId: (id: string) => Promise<Interview | null>
}

const database = getDatabase();

export const useInterviews = (): useInterviewsState => {
  const root = 'interviews';
  const defaultCrud = useTable(root);

  const findByUserId = useCallback(async (id: string): Promise<null | Interview> => {
    const user = await get(query(ref(database, `${root}/${id}`))).catch((e: any) => {
      console.error('Error: findByUserId - ' + id, e);
      return null;
    })

    if (user?.exists()) {
      return user.val() as Interview
    }

    return null;

  }, []);

  return useMemo(() => ({
    ...defaultCrud,
    findByUserId,
  }), [defaultCrud, findByUserId])
};