import React from 'react';
import { Form, FormControlProps } from 'rsuite';

interface TextFieldProps extends FormControlProps{
  label: string;
  type?: string
}

export const TextField: React.FC<TextFieldProps> = (props) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
}