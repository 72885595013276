import React from 'react';
import { FlexboxGrid } from 'rsuite';
import animationData from './assets/error.json';
import Lottie from 'react-lottie';
import { useTitle } from 'hooks/useTitle';

interface ErrorAnimationProps {

}

export const ErrorAnimation: React.FC<ErrorAnimationProps> = () => {
  useTitle('Error');

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <FlexboxGrid justify="center" style={{ padding: 50 }}>
      <FlexboxGrid.Item>
        <h4 style={{ textAlign: 'center'}}>Что то пошло не так :(</h4>
        <Lottie
          options={defaultOptions}
          height={200}
          width={400}
          isStopped={false}
          isPaused={false}
        />
      </FlexboxGrid.Item>

    </FlexboxGrid>
  )
}