import React from 'react';

export { Login } from './login/Login';
export { Invite } from './invite/Invite';
export { Home } from './home/Home';

export const InterviewSummary = React.lazy(() => import(/* webpackChunkName: "InterviewSummary" */ './interview/InterviewSummary'));
export const Interview = React.lazy(() => import(/* webpackChunkName: "Interview" */'./interview/Interview'));
export const InterviewRevision = React.lazy(() => import(/* webpackChunkName: "InterviewRevision" */ './interview/InterviewRevision'));
export const InterviewManagement = React.lazy(() => import(/* webpackChunkName: "InterviewManagement" */'./interview/InterviewManagement'));
export const Profile = React.lazy(() => import(/* webpackChunkName: "Profile" */'./profile/Profile'));

export const QuestionsList = React.lazy(() => import(/* webpackChunkName: "QuestionsList" */'./questions/QuestionsList'));
export const TemplatesList = React.lazy(() => import(/* webpackChunkName: "TemplatesList" */'./templates/TemplatesList'));
export const TemplatesDetails = React.lazy(() => import(/* webpackChunkName: "TemplatesDetails" */'./templates/TemplatesDetails'));
export const TagsList = React.lazy(() => import(/* webpackChunkName: "Tags" */'./tags/TagsList'));

