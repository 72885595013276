import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { getAuth, onAuthStateChanged, User, IdTokenResult } from 'firebase/auth';
import { firebaseApp, firebaseAuth, remoteConfig } from 'configs/firebaseConfig';
import { fetchAndActivate } from 'firebase/remote-config';

const auth = getAuth(firebaseApp);

export const AuthContext = React.createContext<any>(null);

const AUTH_TIMEOUT = 1500;

export const AuthContextProvider: React.FC  = (props) => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(firebaseAuth.currentUser);
  const [loading, setLoading] = useState<boolean>(!firebaseAuth.currentUser);

  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    fetchAndActivate(remoteConfig);
  }, [])

  useEffect(() => {
    timer.current = setTimeout(() => setLoading(false), AUTH_TIMEOUT) as NodeJS.Timeout
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        timer.current && clearTimeout(timer.current as NodeJS.Timeout);
        setUser(user);
      }
    });
    return () => setLoading(false);
  }, [setLoading, isAdmin]);

  // check user claims
  useEffect(() => {

    firebaseAuth.currentUser?.getIdTokenResult()
      .then((idTokenResult: IdTokenResult) => {
        // Confirm the user is an Admin.
        setIsAdmin(!!idTokenResult.claims.admin);
      })
      .catch((error: any) => {
        console.error(error);
        setIsAdmin(false);
      }).finally(() => {
        setLoading(false);
    })

  }, [user, loading]);

  useEffect(() => {
    if (!user) {
      setIsAdmin(false);
    }
  }, [user]);

  const reloadUser = useCallback(() => {
    setUser(firebaseAuth.currentUser)
  }, [setUser])

  const value = useMemo(() => {
    return {
      isAdmin,
      user,
      loading,
      signOut: () => firebaseAuth.signOut(),
      reloadUser,
    }
  },[isAdmin, user, loading]);

  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext)
};
